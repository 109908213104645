$red: #CF000F;
.color-red{
    color: $red;
}

@media screen and (max-width: 320px) {
    .home {
        width: 100%;

        .container {
            justify-content: center;
        }

        .column {
            width: 90%;

            .tiendas {
                font-size: 1.4rem !important;
                padding-bottom: 1.3rem !important;
            }

            .busqueda {
                width: 100%;

                .search_filter {
                    position: relative !important;
                    width: 100% !important;
    
                    i {
                        position: absolute !important;
                        color: #adadad !important;
                        right: 16px !important;
                        top: 15px !important;
                        font-size: 0.9em !important;
                    }
                }

                .gallery-grid {
                    width: 90%;
                    
                    .gallery-cards {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr) !important;
                        grid-gap: 1rem !important;
                        padding-top: 38px !important; 
                        width: 100% !important;
                    }
                }

                .search { 
                    position: relative !important;
                    
                    &__container {
                        padding-top: 9px !important;
                        width: 95%;
                    }
    
                    &__form {
                        //width: 40%;
                        padding: 15px 0 !important;

                        .column {
                            width: 100% !important;

                            .categories {
                                width: 100% !important;
                                font-size: 11px !important;
                            }
                        }
                       
                        .categories2 {
                            margin-left: 0 !important;
                            width: 100% !important;
                            margin-top: 8px;
                            font-size: 11px !important;
                        }
                    }
                }

                .buttons {
                    width: 100% !important;

                    .boton {
                        width: 100% !important;
                        padding-left: 0 !important;
                        padding-top: 4px !important;

                        .botton {
                            width: 100% !important;

                            .btn {
                                width: 100% !important;
                            }  
                        }
                    }
                }
            }

            .contactanos {
                width: 100% !important;
                padding: 1rem !important;

                .column {
                    width: 100% !important;

                    .titulo {
                        font-size: 1.2rem !important;
                        text-align: center;
                    }

                    .titulo2 {
                        font-size: 1.1rem !important;
                        padding-top: 16px;
                    }

                    .titulo3 {
                        color: #ffffff;
                        text-align: center;
                        width: 95%;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                      
                        i {
                            font-size: 1.8em;
                            color:  #ff3980;
                            padding-right: 1rem;
                        }

                        .tel {
                            color: #ff3980;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 100;
                            font-family: 'Montserrat', sans-serif;
                        }
                    }

                    .redes {
                        width: 100%;
                        padding-top: 4rem;
                        padding-bottom: 0.7rem;
                        border-bottom: 2px solid #8d8d8d;
                    }
                }

                .cards {
                    width: 90% !important;
                }

                .row-responsive {
                    width: 90%;

                    .redes {
                        width: 50%;
                        padding-top: 4rem;
                        padding-bottom: 0.7rem;

                        .face {
                            width: 20%;

                            i {
                                font-size: 1.2em;
                                color:  #6b6b6b;
                                align-items: center;
                                
                                &:hover {
                                    color: white ;
                                    border-bottom: 1px solid #ff3980;      
                                } 
                            }
                        }     
                    }
                }
            }
        }

        .footer {
            .redes {
                width: 100% !important;
                padding-top: 0.5rem;
                padding-bottom: 2rem;

                .partRedes {
                    .texto {
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-left: 0rem !important;
                        padding-bottom: 2.5rem;
                        justify-content: center;
                        text-align: center;
                    }

                    .iconosRedes {
                        padding-left: 0rem !important;
                        width: 100% !important;
                        flex-direction: row;
                        justify-content: center;
                        align-content: center;
                        padding-bottom: 1.5rem;

                        .face {
                            width: 18% !important;
                            /* border-bottom: 2px solid #eb556078; */
                            padding-bottom: 0rem;
                            margin-right: 1rem;
                            justify-content: center;

                            i {
                                font-size: 2em;
                                color: #d5d5d5;
                                align-items: center;
                            }

                            .txtface {
                                font-size: 1rem;
                                font-family: 'Montserrat', sans-serif;
                                color: #d5d5d5;
                                padding-left: .5rem;
                                padding-bottom: 0rem;
                                width: 82%;
                                height: 30px;
                                display: none;
                            }
                        }
                    }
                }

                .certificaciones {
                    width: 85%;

                    .texto2 {
                        font-size: 1.2rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-right: 0rem !important;
                        text-align: center;
                        padding-bottom: 1.5rem;
                        /* width: 60%; */
                    }

                    .imgCert {
                        font-size: 1.2rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;

                        .ISO {
                            width: 38% !important;
                            // height: 18%;
                            padding-bottom: 1.3rem;
                            padding-top: 1.3rem;
                        }
                        
                        .IAF {
                            width: 5%;
                        }
                    }
                } 
            }
        }

        .appmospheraFooter {
            padding: 6px 0 0 0 !important;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
            font-size: 12px !important;
            color: #8d8d8d;
            text-align: center;
        }
    }
}

@media screen and (min-width: 321px) and (max-width: 598px) {
    .home {
        width: 100%;

        .container {
            justify-content: center;
        }

        .column {
            .tiendas {
                font-size: 1.5rem !important;
                padding-bottom: 1.3rem !important;
            }

            .busqueda {
                width: 100%;
                background-color: #242a38;
                
                .search_filter {
                    position: relative !important;
                    width: 100% !important;
    
                    i {
                        position: absolute !important;
                        color: #adadad !important;
                        right: 16px !important;
                        top: 15px !important;
                        font-size: 0.9em !important;
                    }
                }
               
                .search { 
                    position: relative !important;
                    
                    &__container {
                        padding-top: 9px !important;
                        width: 95%;
                    }
    
                    &__form{
                        //width: 40%;
                        padding: 15px 0 !important;

                        .column {
                            width: 100% !important;

                            .categories {
                                width: 100% !important;
                                font-size: 11px !important;
                            }
                        }
                       
                        .categories2 {
                            margin-left: 0 !important;
                            width: 100% !important;
                            margin-top: 8px;
                            font-size: 11px !important;
                        }
                    }
                    
                    &__input {
                        font-size: 12.5px !important;
                    }
                }

                .buttons {
                    width: 100% !important;

                    .boton {
                        width: 100% !important;
                        padding-left: 0 !important;
                        padding-top: 4px !important;

                        .botton {
                            width: 100% !important;

                            .btn {
                                width: 100% !important;
                            }  
                        }
                    }
                }
    
                .gallery-grid {
                    width: 90%;
                    
                    .gallery-cards {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr) !important;
                        grid-gap: 1rem !important;
                        padding-top: 38px !important; 
                        width: 100%  !important;
                    }
                }

                .resultados {
                    padding-top: 2rem !important;
                    color:#ffffff !important;
                    text-align: center !important;
                    font-weight: 30px;
                    font-size: 4rem;
                }
            }  

            .empresas {
                //width: 90% !important;  
                //padding-left: 24px;
                padding-bottom: 38px;
    
                .container {
                    padding-bottom: 1rem !important;
                    width: 95%;

                    .titulos {
                        text-align: start !important;

                        .h1 {
                            color: #ff3980;
                            text-align: start !important;
                            font-weight: 500 !important;
                        } 
                    }
                }

                .white-space-64 {
                    height: 32px !important;
                }
            }

            .contactanos {
                width: 100% !important;
                padding: 1rem !important;

                .column {
                    width: 95%;

                    .titulo {
                        font-size: 1.4rem !important;
                        text-align: center;
                    }

                    .titulo2 {
                        font-size: 1.3rem !important;
                        padding-top: 16px;
                    }

                    .titulo3 {
                        color: #ffffff;
                        text-align: center;
                        width: 95%;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                      
                        i {
                            font-size: 1.8em;
                            color:  #ff3980;
                            padding-right: 1rem;
                        }

                        .tel {
                            color: #ff3980;
                            text-align: center;
                            font-size: 18px;
                            font-weight: 100;
                            font-family: 'Montserrat', sans-serif;
                        }
                    }

                    .redes {
                        width: 100%;
                        padding-top: 4rem;
                        padding-bottom: 0.7rem;
                        border-bottom: 2px solid #8d8d8d;
                    }
                }

                .row-responsive {
                    width: 90%;

                    .redes {
                        width: 50%;
                        padding-top: 4rem;
                        padding-bottom: 0.7rem;

                        .face {
                            width: 20%;

                            i {
                                font-size: 1.2em;
                                color:  #6b6b6b;
                                align-items: center;
                                
                                &:hover {
                                    color: white ;
                                    border-bottom: 1px solid #ff3980;      
                                } 
                            }
                        }     
                    }
                }
            }
        }

        .footer {
            .redes {
                width: 100% !important;
                padding-top: 0.5rem;
                padding-bottom: 2rem;

                .partRedes {
                    .texto {
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-left: 0rem !important;
                        padding-bottom: 2.5rem;
                        justify-content: center;
                        text-align: center;
                    }

                    .iconosRedes {
                        padding-left: 0rem !important;
                        width: 100% !important;
                        flex-direction: row;
                        justify-content: center;
                        align-content: center;
                        padding-bottom: 1.5rem;

                        .face {
                            width: 18% !important;
                            /* border-bottom: 2px solid #eb556078; */
                            padding-bottom: 0rem;
                            margin-right: 1rem;
                            justify-content: center;

                            i {
                                font-size: 2em;
                                color: #d5d5d5;
                                align-items: center;
                            }

                            .txtface {
                                font-size: 1rem;
                                font-family: 'Montserrat', sans-serif;
                                color: #d5d5d5;
                                padding-left: .5rem;
                                padding-bottom: 0rem;
                                width: 82%;
                                height: 30px;
                                display: none;
                            }
                        }
                    }
                }

                .certificaciones {
                    width: 85%;

                    .texto2 {
                        font-size: 1.2rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-right: 0rem !important;
                        text-align: center;
                        padding-bottom: 1.5rem;
                        /* width: 60%; */
                    }

                    .imgCert {
                        font-size: 1.2rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;

                        .ISO {
                            width: 38% !important;
                            //height: 18%;
                            padding-bottom: 1.3rem;
                            padding-top: 1.3rem;
                        }
                        
                        .IAF {
                            width: 5%;
                        }
                    }
                } 
            }
        }

        .appmospheraFooter {
            padding: 6px 0 0 0 !important;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
            color: #8d8d8d;
            text-align: center;
        }
    }
}

@media screen and (min-width: 768px) and (max-width:1023px) {
    .home {
        .column {
            .tiendas {
                font-size: 1.9rem !important;
            }

            .busqueda {
                .gallery-grid {
                    width: 90%;
                    
                    .gallery-cards {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr) !important;
                        grid-gap: 1rem !important;
                        padding-top: 38px !important; 
                        width: 100%  !important;
                    }
                }

                .search_filter {
                    width: 65% !important;
                }

                .search__form {
                    justify-content: flex-start;

                    .categories, .categories2 {
                        width: 100% !important;
                    }
                }

                .selects-tablet, .selects-desk {
                    padding-left: 0 !important;
                }

                .filtro-tablet {
                    padding-top: 24px;

                    .buttons {
                        width: 30% !important;
                    }
                }

                .filtro-desk {
                    padding-top: 24px;

                    .buttons {
                        width: 40% !important;

                        .boton {
                            justify-content: flex-end;
                        }

                        .btn {
                            padding: 7px 24px;
                        }
                    }
                }

                .container-desk {
                    flex-direction: column;
                }
            }

            .contactanos {
                .titulo {
                    font-size: 1.9rem !important;
                    text-align: center;
                }

                .titulo2 {
                    font-size: 1.8rem !important;
                    padding-top: 16px;
                }
            }
        }

        .footer {
            .redes {
                width: 100% !important;
                padding-top: 24px !important;
                padding-bottom: 2rem;

                .partRedes {
                    .texto {
                        font-size: 1.1rem !important;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-left: 0rem !important;
                        padding-bottom: 2.5rem;
                        justify-content: center;
                        text-align: center;
                    }

                    .iconosRedes {
                        padding-left: 0rem !important;
                        width: 100% !important;
                        flex-direction: row;
                        justify-content: center;
                        align-content: center;
                        padding-bottom: 1.5rem;

                        .face {
                            width: 18% !important;
                            padding-bottom: 0rem;
                            margin-right: 1rem;
                            justify-content: center;

                            i {
                                font-size: 2.4em !important;
                                color: #d5d5d5;
                                align-items: center;
                            }

                            .txtface {
                                font-size: 1rem;
                                font-family: 'Montserrat', sans-serif;
                                color: #d5d5d5;
                                padding-left: .5rem;
                                padding-bottom: 0rem;
                                width: 82%;
                                height: 30px;
                                display: none;
                            }
                        }
                    }
                }

                .certificaciones {
                    width: 90% !important;
                    //padding-top: 16px;

                    .texto2 {
                        font-size: 1.1rem !important;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-right: 0rem !important;
                        text-align: center;
                        padding-bottom: 1.5rem;
                    }

                    .imgCert {
                        font-size: 1.2rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;

                        .ISO {
                            width: 23% !important;
                            padding-bottom: 1.3rem;
                            padding-top: 1.3rem;
                        }
                        
                        .IAF {
                            width: 30% !important;
                            margin-left: 2rem;
                            margin-right: 3rem;
                        }
                    }
                } 
            }
        }

        .appmospheraFooter {
            padding: 6px 0 0 0 !important;
            font-weight: 600;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px !important;
            color: #8d8d8d;
            text-align: center;
        }
    }
}

/*@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .home {
        .column {
            .titulo {
                text-align: center;
            }

            .contactanos {
                .column {
                    width: 95% !important;

                    .titulo2 {
                        padding-top: 24px;
                    }
                }
            }

            .footer {
                .redes {
                    width: 100% !important;

                    .certificaciones {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}*/

@media screen and (min-width: 898px) and (max-width: 1078px) {
    .home {
        .busqueda {
            .resultados {
                font-size: 1.8rem !important;
            }

            .search__input, .categories, .categories2 {
                font-size: 11px !important;
            }

            .buttons {
                .boton {
                    .btn {
                        font-size: 11px !important;
                    }
                }
            }

            .search_filter {
                width: 50% !important;
            }
        }

        .tiendas {
            font-size: 2rem !important;
        }

        .contactanos {
            .titulo {
                font-size: 2.1rem !important;
            }

            .titulo2 {
                font-size: 1.8rem !important;
            }
        }

        .footer {
            .redes {
                align-items: flex-start !important;
                width: 100% !important;

                .texto, .texto2 {
                    font-size: 1rem !important;
                }

                .partRedes {
                    .iconosRedes .face i {
                        font-size: 1.2em !important;
                    }

                    .iconosRedes .face .txtface {
                        font-size: 0.9rem !important;
                        height: 0 !important;
                    }
                }
            }

            .appmospheraFooter {
                padding: 0rem 0 !important;
                font-size: 11px !important;
            }
        }
    }
}

@media screen and (min-width: 1079px) and (max-width: 1225px) {
    .home {
        .busqueda {
            .resultados {
                font-size: 1.8rem !important;
            }

            .search__input, .categories, .categories2 {
                font-size: 11px !important;
            }

            .buttons {
                .boton {
                    .btn {
                        font-size: 11px !important;
                    }
                }
            }

            .search_filter {
                width: 50% !important;
            }
        }

        .tiendas {
            font-size: 1.9rem !important;
        }

        .contactanos {
            .titulo {
                font-size: 2.1rem !important;
            }

            .titulo2 {
                font-size: 1.8rem !important;
            }
        }

        .footer {
            .redes {
                align-items: flex-start !important;
                width: 100% !important;

                .texto, .texto2 {
                    font-size: 1rem !important;
                }

                .partRedes {
                    .iconosRedes .face i {
                        font-size: 1.2em !important;
                    }

                    .iconosRedes .face .txtface {
                        font-size: 0.9rem !important;
                        height: 0 !important;
                    }
                }
            }

            .appmospheraFooter {
                padding: 0rem 0 !important;
                font-size: 11px !important;
            }
        }
    }
}

@media screen and (min-width: 1226px) and (max-width: 1349px) {
    .home {

        .busqueda {
            .resultados {
                font-size: 2rem !important;
            }    
        }

        .footer {
            .redes {
                width: 95% !important;
            }
        }
    }
}

@media screen and (min-width: 2698px) {
    .home {
        .busqueda {
            .tiendas-dest {
                padding-top: 64px;

                .tiendas {
                    font-size: 5rem !important;
                }
            }

            .gallery-grid {
                width: 90%;
                
                .gallery-cards {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr) !important;
                    grid-gap: 1rem !important;
                    padding-top: 64px !important; 
                    width: 100%  !important;
                }
            }

            .resultados {
                font-size: 4rem !important;
            }
        }

        .contactanos {
            .titulo {
                font-size: 5rem !important;
                padding-top: 64px;
            }

            .titulo2 {
                font-size: 4.5rem !important;
                padding: 72px 0;
            }

            .cards {
                .phone, .email {
                    font-size: 42px !important;
                    width: 25% !important;
                    height: 500px !important;

                    .fa {
                        font-size: 2.5em !important;
                        padding-bottom: 32px;
                    }
                }

                padding-bottom: 64px;
            }
        }

        .footer {
            .appmospheraFooter {
                padding: 1rem 0 !important;
                font-size: 24px !important;
            }

            .redes {
                align-items: flex-start !important;

                .texto {
                    font-size: 2.5rem !important;
                }

                .partRedes {
                    a {
                        font-size: 3rem !important;
                    }

                    .face i {
                        font-size: 3rem !important;
                    }

                    .txtface {
                        font-size: 2.3rem !important;
                        padding-left: 1.5rem !important;
                    }
                }

                .certificaciones {
                    .texto2 {
                        font-size: 2.5rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 4045px) {
    .home {
        .busqueda {
            .tiendas-dest {
                padding-top: 64px;

                .tiendas {
                    font-size: 7rem !important;
                }
            }

            .gallery-grid {
                width: 90%;
                
                .gallery-cards {
                    display: grid;
                    grid-template-columns: repeat(6, 1fr) !important;
                    grid-gap: 1rem !important;
                    padding-top: 64px !important; 
                    width: 100%  !important;
                }
            }
        }

        .contactanos {
            .titulo {
                font-size: 7.5rem !important;
                padding-top: 64px;
            }

            .titulo2 {
                font-size: 6.8rem !important;
                padding: 72px 0;
            }

            .cards {
                .phone, .email {
                    font-size: 42px !important;
                    width: 25% !important;
                    height: 500px !important;

                    .fa {
                        font-size: 3.5em !important;
                        padding-bottom: 32px;
                    }
                }

                padding-bottom: 64px;
            }
        }

        .footer {
            .appmospheraFooter {
                padding: 1rem 0 !important;
                font-size: 32px !important;
            }

            .redes {
                align-items: flex-start !important;

                .texto {
                    font-size: 3.5rem !important;
                }

                .partRedes {
                    a {
                        font-size: 3rem !important;
                    }

                    .face i {
                        font-size: 3rem !important;
                    }

                    .txtface {
                        font-size: 2.5rem !important;
                        padding-left: 1.5rem !important;
                    }
                }

                .certificaciones {
                    .texto2 {
                        font-size: 3.5rem !important;
                    }
                }
            }
        }
    }
}

/*@media screen and (min-width: 577px) and (max-width: 810px) {

}

@media screen and (min-width: 811px) and (max-width:992px) {

}

@media screen and (min-width: 993px) and (max-width: 1188px) {

}

@media screen and (min-width: 1189px) and (max-width: 1300px) {

}*/

.home {
    width: 100%;

    .column{
        width: 100%;

        .filters {
            width: 100%;
        }

        .tiendas{
            font-size: 2.5rem;
            font-weight: 300;
            font-family: 'Montserrat', sans-serif;
            letter-spacing: 1px;
            padding-bottom: 2.5rem;
            color: #ff3980;
        }

        .busqueda{
            width: 100%;
            background-color: #242a38;
            
            .search_filter {
                position: relative !important;
                width: 55%;

                i {
                    position: absolute !important;
                    color: #adadad !important;
                    right: 16px !important;
                    top: 15px !important;
                    font-size: 0.9em !important;
                }
            }
           
            .search { 
                position: relative !important;
                
                &__container {
                    padding-top: 9px !important;
                    width: 90%;
                }

                &__form{
                    //width: 40%;
                    padding-left: 26px;
                    .column{
                        width: 80%;
                        .categories{
                            width: 90%;
                            background-color: transparent !important;
                            transition: transform 250ms ease-in-out !important;
                            font-size: 14px;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 200 ;
                            letter-spacing: 1.5px !important;
                            text-transform: uppercase !important;
                            line-height: 18px !important;
                            padding: 12px  !important;
                            color: #adadad !important;
                            background-color: transparent !important;
                            /*         background-image: url(http://mihaeltomic.com/codepen/input-search/ic_search_black_24px.svg); */
                     
                            background-image: url("data:image/svg+xml; charset=utf8,%3Csvg xmlns='https://www.w3.org/TR/SVG2/' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !important;
                            background-repeat: no-repeat !important;
                            background-size: 18px 18px !important;
                            background-position: 95% center !important;
                        
                            border-bottom: 1px solid #ff3980 !important;
                            transition: all 250ms ease-in-out !important;
                            backface-visibility: hidden !important;
    
                            &:hover,
                            &:focus {
                                padding: 12px 0 !important;
                                outline: 0 !important;
                                border: 1px solid transparent !important;
                                border-bottom: 2px solid #ff3980 !important;
                                border-radius: 0;
                                background-position: 100% center !important;
                                color:#ff3980  !important;
                            }
                        }
                    }
                   
                    .categories2 {
                        margin-left: 15px;
                        width: 90%;
                        background-color: transparent !important;
                        transition: transform 250ms ease-in-out !important;
                        font-size: 14px;
                        font-family: 'Montserrat', sans-serif;
                            font-weight: 200 ;
                        letter-spacing: 1.5px !important;
                        text-transform: uppercase !important;
                 
                        line-height: 18px !important;
                        padding: 12px  !important;

                        color: #adadad !important;
                        background-color: transparent !important;
                        /*         background-image: url(http://mihaeltomic.com/codepen/input-search/ic_search_black_24px.svg); */
                 
                        background-image: url("data:image/svg+xml; charset=utf8,%3Csvg xmlns='https://www.w3.org/TR/SVG2/' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !important;
                        background-repeat: no-repeat !important;
                        background-size: 18px 18px !important;
                        background-position: 95% center !important;
                    
                        border-bottom: 1px solid #ff3980 !important;
                        transition: all 250ms ease-in-out !important;
                        backface-visibility: hidden !important;

                        &:hover,
                        &:focus {
                            padding: 12px 0 !important;
                            outline: 0 !important;
                            border: 1px solid transparent !important;
                            border-bottom: 2px solid #ff3980 !important;
                            border-radius: 0;
                            background-position: 100% center !important;
                            color:#ff3980 !important;
                        }
                    }
                }
                
                &__input {
                    position: relative !important;
                    //width: 60% !important;
                    width: 100% !important;
                    padding: 12px 12px !important;
            
                    background-color: transparent !important;
                    transition: transform 250ms ease-in-out !important;
                    font-size: 14px;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 200 ;
                    line-height: 18px !important;
                    
                    color: #adadad !important;
                    background-color: transparent !important;
                    /*         background-image: url(http://mihaeltomic.com/codepen/input-search/ic_search_black_24px.svg); */
             
                    background-image: url("data:image/svg+xml; charset=utf8,%3Csvg xmlns='https://www.w3.org/TR/SVG2/' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E") !important;
                    background-repeat: no-repeat !important;
                    background-size: 18px 18px !important;
                    background-position: 95% center !important;
                    border-radius: 11px !important;
                    border: 1px solid #ff3980 !important;
                    transition: all 250ms ease-in-out !important;
                    backface-visibility: hidden !important;
                    transform-style: preserve-3d !important;
                    
                    &::placeholder {
                        color: color(#ff3980 a(0.8)) !important;
                        text-transform: uppercase !important;
                        letter-spacing: 1.5px !important;
                        
                    }
                    
                    &:hover,
                    &:focus {
                        padding: 12px 0 !important;
                        outline: 0 !important;
                        border: 1px solid transparent !important;
                        border-bottom: 1px solid #ff3980 !important;
                        border-radius: 0 !important;
                        background-position: 100% center !important;
                    }
                }
            }

            .buttons {
                width: 30%;

                .boton {
                    width: 100%;
                    padding-left: 10px;

                    .btn {
                        background-color: #ff3980;
                        color: white;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        
                        &:hover {
                            color: white ;
                            background-color: #ff3980;
                            border: 1px solid #ff3980;
                           
                        }

                        border: 1px solid  #ff3980; 
                        border-radius: 10px;
                    }  
                }
            }

            .gallery-grid {
                width: 90%;
                
                .gallery-cards {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 1rem;
                    padding-top: 38px; 
                    width: 100%;
                  
                }
            }

            .resultados {
                padding-top: 2rem !important;
                color:#ffffff !important;
                text-align: center !important;
                font-weight: 30px;
                font-size: 2.3rem;
            }
        }  

        .empresas {
            //width: 90% !important;  
            //padding-left: 24px;
            padding-bottom: 38px;

            .container {
                padding-bottom: 1rem !important;
              
                width: 95%;

                .titulos {
                    text-align: start !important;

                    .h1 {
                        color:ff3980;
                        text-align: start !important;
                        font-weight: 500 !important;
                    } 
                }
            }
        }

        .contactanos {
            width: 100% !important;
            padding: 1rem !important;

            .column {
                width: 95%;

                .titulo {
                    color:#ffffff;
                    font-weight: 200;
                    font-size: 2.5rem ;
                    font-family: 'Montserrat', sans-serif;
                    letter-spacing: 1.2px;
                }

                .titulo2 {
                    color: #ffffff;
                    text-align: center;
                    font-size: 2.2rem;
                    font-weight: 550;
                    font-family: 'Montserrat', sans-serif;
                    letter-spacing: 1px;
                }

                .contact {
                    width: 85%;
                    padding-bottom: 8rem;
                    border-bottom: 2px solid white;

                    .titulo3 {
                        color: #ffffff;
                        text-align: center;
                        width: 95%;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
    
                        i {
                            font-size: 2.2em;
                            color:  #ff3980;
                            padding-right: 1rem;
                        }

                        .tel {
                            color: #ff3980;
                            text-align: center;
                            font-size: 22px;
                            font-weight: 100;
                            font-family: 'Montserrat', sans-serif;
                               
                        }
                    }
                }

				.cards {
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;

					.email, .phone {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding: 10px;
						height: 200px;
						width: 280px;
						color: white;
						margin: 10px;
						font-family: 'Montserrat', sans-serif;

						@media screen and (max-width: 768px) {
							width: 50%;
							margin: 1px;
							font-size: 3vw;
						}
					}

					.email {
						background-color: #3d3d3d;
						.fa {
							color: #ff3980;
							font-size: 3rem;
						}
					}

					.phone {
						background-color: #ff3980;
						.fa {
							color: white;
							font-size: 3rem;
						}
					}
				}
            }

            .column {
                width: 90%;

                .redes1 {
                    width: 100%;
                    padding-top: 8rem;
                    padding-bottom: 0.7rem;
                    border-bottom: 2px solid #8d8d8d;

                    .face {
                        width: 5%;

                        i {
                            font-size: 1.2em;
                            color:  #6b6b6b;
                            align-items: center;

                            &:hover {
                                color: white ;
                                border-bottom: 1px solid #ff3980;
                            } 
                        }
                    }
                }
            }
        }  
    }
}

