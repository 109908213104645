.map {
	padding-top: 24px;
	width: 100%;
	//height: 500px;
	height: 100%;
	//max-height: 500px;
	overflow: hidden;
	position: relative;
}

.mapa {
	height: 100%;

	@media screen and (max-width: 568px) {
		height: 500px !important;
	}
}

.desc-map {
	div {
		height: 100%;
	}
}