.page-microsite {
	display: flex;
	flex-direction: column;

	.container {
		position: relative;
	}

	.header-img {
		width: inherit;
	}

	#introduccion {
		width: 100%;
		display: flex;
		justify-content: space-around;
		position: relative;
		margin-top: 30px;

		/*@media screen and (max-width: 768px) {
			flex-wrap: wrap;
			margin-top: 120px;
		}*/

		@media screen and (max-width: 568px) {
			/*width: 75px;
			height: 75px;
			margin-left: 30px;
			bottom: 27px;*/
			margin-top: 40px;
		}

		.logo {
			width: 220px;
			height: 220px;
			margin-left: 90px;
			position: absolute;
			z-index: 2;
			bottom: -35px;
			left: 0px;
			background-color: white;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			box-shadow: 2px 4px 10px #3d3d3d74;
			padding: 10px;
			background-position: center;
    		background-size: contain;
			background-repeat: no-repeat;

			/*@media screen and (max-width: 768px) {
				width: 180px;
				height: 180px;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				bottom: 70px;
				text-align: center;
			}*/

			@media screen and (max-width: 568px) {
				width: 80px;
				height: 80px;
				margin-left: 30px;
				bottom: 16px;
			}
		}
	}

	.descripcion {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-gap: 35px;
		overflow: hidden;
		padding-top: 65px;

		/*#descripcion_imagen {
			a {
				cursor: pointer;
				background-color: #ff3980;
				color: white;
				padding: 10px 32px;

				&:hover {
					background-color: #e42a6e;
					color: white;
					//padding: 10px 32px;
				}
			}
		}*/

		@media screen and (max-width: 568px) {
			grid-template-columns: 100%;
			padding: 10px;
		}

		section {
			display: flex;
			width: auto;

			@media screen and (max-width: 768px) {
				width: auto;
			}

			.text {
				font-family: 'Montserrat', sans-serif;
				font-size: 1rem;
			}
		}
	}

	#coleccion_imagenes {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
  		grid-gap: 20px;
		width: 100%;
		margin-top: 50px;

		@media screen and (max-width: 568px) {
			grid-template-columns: 1fr;
			overflow: hidden;
		}

		.imagen-producto {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-bottom: 20px;
			margin-right: 20px;

			img {
				width: 100%;
				align-self: flex-start;

				/*@media screen and (max-width: 768px) {
					max-height: auto;
				}*/
			}
		}
	}

	#calificaciones {
		margin-top: 20px;
		display: flex;

		/*@media screen and (max-width: 768px) {
			padding: 10px;
		}*/

		.title {
			color: #ff3980;
		}

		.calificacion {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			.number {
				color: #ff3980;
				font-weight: bold;
				font-weight: bold;
				font-family: 'Montserrat', sans-serif;
				color: #ff3980;
				font-size: 22px;
			}

			.pink {
				color: #ff3980;
				font-size: 22px;
			}
		}
	}

	#contactanos {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 20px;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
			color: #ff3980;
			//font-size: 22px;
			font-weight: bold;
			font-family: 'Montserrat', sans-serif;
			color: #ff3980;
		}

		.cards {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;

			.email, .phone {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 10px;
				height: 200px;
				width: 280px;
				color: white;
				margin: 10px;
				font-family: 'Montserrat', sans-serif;

				/*@media screen and (max-width: 768px) {
					width: 50%;
					margin: 1px;
					font-size: 3vw;
				}*/
			}

			.email {
				background-color: #3d3d3d;
				.fa {
					color: #ff3980;
					font-size: 3rem;
				}
			}

			.phone {
				background-color: #ff3980;
				.fa {
					color: white;
					font-size: 3rem;
				}
			}
		}
	}

	.title {
		font-weight: bold;
		font-family: 'Montserrat', sans-serif;
		font-size: 22px;
		color: #ff3980;
	}

	.title2 {
		font-size: 42px;
		/*@media screen and (max-width: 768px) {
			font-size: 1.5rem !important;
		}*/
	}

	.title3 {
		font-size: 32px;
		/*@media screen and (max-width: 768px) {
			font-size: 1rem !important;
		}*/
	}

	.title4 {
		font-size: 28px;
		font-weight: 300 !important;
		/*@media screen and (max-width: 768px) {
			font-size: 1rem !important;
		}*/
	}

	.title5 {
		font-size: 16px;
		/*@media screen and (max-width: 768px) {
			font-size: 0.8rem !important;
		}*/
	}

	.pink {
		color: #ff3980;
	}

	button, .button {
		cursor: pointer;
		background-color: #ff3980;
		color: white;
		padding: 10px 32px;

		&:hover {
			background-color: #e42a6e;
			color: white;
			//padding: 10px 32px;
		}
	}

	.whatsapp {
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 99;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: #00bb2d;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		padding: 10px;
		font-size: 2.5vw;
		cursor: pointer;

		/*@media screen and (max-width: 768px) {
			bottom: 40px;
			right: 20px;
			font-size: 8vw;
		}*/
	}
}

@media screen and (max-width: 568px) { 
	.page-microsite {
		.descripcion {
			.desc-map {
				h2 {
					font-size: 1.5rem;
				}
			}
		}

		#calificaciones {
			.title {
				font-size: 1.1rem;
			}
				
			.calificacion {
				.number, .pink {
					font-size: 1.1rem;
				}
			}
		}

		#contactanos {
			.title2 {
				font-size: 1.4rem !important;
			}

			.title3 {
				font-size: 0.9rem;
			}

			.title4 {
				font-size: 0.9rem;
			}

			.cards {
				.email, .phone {
					width: 63%;
					height: 120px;
					margin: 10px;
					font-size: 0.7rem;

					i {
						font-size: 1.8rem !important;
					}
				}
			}

			.title5 {
				font-size: 0.6rem;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width:1023px) {
	.page-microsite {
		#introduccion {
			.logo {
				width: 130px;
				height: 130px;
				margin-left: 68px;
				bottom: -13px;
			}
		}

		.descripcion {
			padding-top: 40px;

			#descripcion_imagen {
				button {
					font-size: 0.6rem;
   					padding: 6px 24px;
				}
			}

			.desc-map {
				h2 {
					font-size: 1.5rem;
				}

				p {
					font-size: 0.65rem;
    				line-height: 15px;
				}
			}
		}

		#calificaciones {
			.title {
				font-size: 0.8rem;
			}

			.calificacion {
				//padding-top: 16px;

				.number, .pink {
					font-size: 0.8rem;
				}
			}
		}

		#contactanos {
			.title2 {
				font-size: 1.5rem !important;
			}

			.title3 {
				font-size: 1.2rem;
			}

			.title4 {
				font-size: 1.2rem;
			}

			.cards {
				.email, .phone {
					width: 23%;
					height: 120px;
					margin: 16px;
					font-size: 0.7rem;

					i {
						font-size: 1.8rem !important;
					}
				}
			}

			.title5 {
				font-size: 0.8rem;
			}
		}
	}
}

@media screen and (min-width: 1226px) and (max-width: 1349px) {
	.page-microsite {
		#introduccion {
			.logo {
				width: 200px;
				height: 200px;
				bottom: -48px;
				left: -10px;
			}
		}

		.descripcion {
			padding-top: 84px;

			#descripcion_imagen {
				button {
					padding: 6px 32px;
    				font-size: 0.8rem;
				}
			}

			.desc-map {
				h2 {
					font-size: 2.3rem;
				}

				p {
					font-size: 0.95rem;
    				line-height: 24px;
				}
			}
		}

		#calificaciones {
			.title {
				font-size: 1.3rem;
			}

			.calificacion {
				//padding-top: 16px;

				.number, .pink {
					font-size: 1.3rem;
				}
			}
		}

		#contactanos {
			.title2 {
				font-size: 2.3rem !important;
			}

			.title3 {
				font-size: 1.7rem;
			}

			.title4 {
				font-size: 1.5rem;
			}

			.cards {
				.email, .phone {
					width: 23%;
					height: 210px;
					margin: 16px;
					font-size: 1rem;

					i {
						font-size: 3rem !important;
					}
				}
			}

			.title5 {
				font-size: 1rem;
			}
		}
	}
}

@media screen and (min-width: 2698px) {
	.page-microsite {
		#introduccion {
			.logo {
				width: 16%;
				height: 390px;
				bottom: -96px;
				left: 66px;
			}
		}

		.descripcion {
			padding-top: 175px;

			#descripcion_imagen {
				width: 95%;

				button {
					padding: 16px 64px;
    				font-size: 1.8rem;
				}
			}

			.desc-map {
				h2 {
					font-size: 5rem;
				}

				p {
					font-size: 2rem;
    				line-height: 48px;
				}
			}
		}

		#coleccion_imagenes {
			grid-gap: 70px;
			margin-top: 70px;
		}

		#calificaciones {
			.title {
				font-size: 2.8rem;
			}

			.calificacion {
				padding-top: 16px;

				.number, .pink {
					font-size: 2.8rem;
				}
			}
		}

		#contactanos {
			.white-space-8 {
				height: 16px;
			}

			.title2 {
				font-size: 5rem !important;
			}

			.title3 {
				font-size: 4rem;
			}

			.title4 {
				font-size: 3.5rem;
			}

			.cards {
				.email, .phone {
					width: 23%;
    				height: 420px;
					margin: 24px;
					font-size: 2.3rem;

					i {
						font-size: 6rem !important;
					}
				}
			}

			.title5 {
				font-size: 2rem;
			}
		}
	}
}

@media screen and (min-width: 4045px) {
	.page-microsite {
		#introduccion {
			.logo {
				width: 18%;
				height: 658px;
				bottom: -182px;
				left: 179px;
			}
		}

		.descripcion {
			padding-top: 280px;

			#descripcion_imagen {
				width: 96%;

				button {
					padding: 24px 75px;
    				font-size: 2.5rem;
				}
			}

			.desc-map {
				h2 {
					font-size: 7.5rem;
				}

				p {
					font-size: 3rem;
    				line-height: 64px;
				}
			}
		}

		#coleccion_imagenes {
			grid-gap: 80px;
			margin-top: 80px;
		}

		#calificaciones {
			.title {
				font-size: 3.8rem;
			}

			.calificacion {
				padding-top: 16px;

				.number, .pink {
					font-size: 4rem;
				}
			}
		}

		#contactanos {
			.white-space-8 {
				height: 16px;
			}

			.title2 {
				font-size: 7.5rem !important;
			}

			.title3 {
				font-size: 5.5rem;
			}

			.title4 {
				font-size: 5rem;
			}

			.cards {
				.email, .phone {
					width: 23%;
    				height: 550px;
					margin: 32px;
					font-size: 3rem;

					i {
						font-size: 8rem !important;
					}
				}
			}

			.title5 {
				font-size: 3rem;
			}
		}
	}
}