/* Widget */
#whatsAppWidget {
	position: fixed;
	right: 17px;
	bottom: 20px;
	float: right;
	margin-bottom: 0px;
	width: 0%;
}

#whatsAppWidget .waArea:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

#whatsAppWidget .waArea {
  float: right;
  //height: 50px;
  //width: 50px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
  text-align: center;
  //line-height: 50px;
  //color: white;
  background-color: rgb(77, 194, 71);
}

#whatsAppWidget svg {
  display: inline;
  fill: white;
  margin: 2px;
  width: 47px;
}

@media screen and (min-width:1800px){
  #whatsAppWidget {
    position: fixed;
    right: 17px;
    bottom: 20px;
    float: right;
    margin-bottom: 0px;
    width: 0%;
  }
  
  #whatsAppWidget .waArea:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  
  #whatsAppWidget .waArea {
    float: right;
    //height: 50px;
    //width: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    text-align: center;
    //line-height: 50px;
    //color: white;
    background-color: rgb(77, 194, 71);
  }
  
  #whatsAppWidget svg {
    display: inline;
    fill: white;
    margin: 8px;
    width: 47px;
  }
  
}
@media screen and (min-width:2700px){
  #whatsAppWidget {
    position: fixed;
    right: 17px;
    bottom: 20px;
    float: right;
    margin-bottom: 29px;
    width: 0%;
  }
  
  #whatsAppWidget .waArea:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  
  #whatsAppWidget .waArea {
    float: right;
    //height: 50px;
    //width: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
    text-align: center;
    //line-height: 50px;
    //color: white;
    background-color: rgb(77, 194, 71);
  }
  
  #whatsAppWidget svg {
    display: inline;
    fill: white;
    margin: 25px;
    width: 47px;
    font-size: 2rem;
  }
  
}

@media screen and (max-width: 568px) { 
  .whatsapp {
    width: 38px !important;
    height: 38px !important;
    font-size: 1.6rem !important;
  }
}

@media screen and (min-width: 768px) and (max-width:1023px) {
  .whatsapp {
    width: 30px !important;
    height: 30px !important;
    bottom: 20px !important;
    right: 20px !important;
  }
}

@media screen and (min-width: 1226px) and (max-width: 1349px) {
  .whatsapp {
    width: 45px !important;
    height: 45px !important;
    bottom: 20px !important;
    right: 20px !important;
  }
}

@media screen and (min-width: 2698px) {
  .whatsapp {
    width: 100px !important;
    height: 100px !important;
    bottom: 30px !important;
    right: 30px !important;
  }
}

@media screen and (min-width: 4045px) {
  .whatsapp {
    width: 150px !important;
    height: 150px !important;
    bottom: 34px !important;
    right: 34px !important;
  }
}