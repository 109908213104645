.footer-home {
    padding-top: .5rem;

    .redes {
        width: 85%;
        padding-top: 0.5rem;
        padding-bottom: 2rem;

        .partRedes {
            .texto {
                font-size: 1.2rem;
                font-family: 'Montserrat', sans-serif;
                color: #d5d5d5;
                padding-left: 3rem;
                padding-bottom: 2.5rem;
            }

            .iconosRedes {
                padding-left: 4rem;
                width: 59%;

                .face {
                    width: 46%;
                    /* border-bottom: 2px solid #eb556078; */
                    padding-bottom: 0rem;
                    margin-right: 1rem;

                    i {
                        font-size: 2em;
                        color: #d5d5d5;
                        align-items: center;
                    }

                    .txtface {
                        font-size: 1rem;
                        font-family: 'Montserrat', sans-serif;
                        color: #d5d5d5;
                        padding-left: .5rem;
                        padding-bottom: 0rem;
                        width: 82%;
                        height: 30px;
                    }
                }
            }
        }

        .certificaciones {
            width: 85%;

            .texto2 {
                font-size: 1.2rem;
                font-family: 'Montserrat', sans-serif;
                color: #d5d5d5;
                padding-right: 4rem;
                padding-bottom: 1.5rem;
                /* width: 60%; */
                justify-content: center;
            }

            .imgCert {
                font-size: 1.2rem;
                font-family: 'Montserrat', sans-serif;
                color: #d5d5d5;

                .ISO {
                    width: 23%;
                    height: 18%;
                    padding-bottom: 1.3rem;
                    padding-top: 1.3rem;
                }

                .IAF {
                    width: 30%;
                    height: auto !important;
                    margin-left: 2rem;
                    margin-right: 3rem;
                }
            }
        }
    }

    .appmospheraFooter {
        padding: 0rem 0;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: #8d8d8d;
        }
    }