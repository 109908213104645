.image-zoom-modal{
    display: none; 
    position: fixed; 
    z-index: 100; 
    /*padding-top: 10px; */
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    /*background-color: rgb(0,0,0); */
    background-color: rgba(119, 117, 117, 0.582);

}

#image-zoom-modal {
    display: none;
}

.modal-content-zoom {
    background-color: #efefde00;
    margin: 85px;
    //align-content: center;
    //justify-content: center;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
    width: auto;
    max-height: 80%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.img-zoom{
    max-height: 8vh;
}

#modal-close-zoom
{
    background-color: #efefde00;
    color: white;
    font-weight: 900;
    cursor: pointer;
}