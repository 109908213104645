.pagination .page-link {
    background-color: transparent;
    padding: 6px 11px;
    color: #9ca0a3;
    float: left;
    text-decoration: none;
    font-size: 20px;
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
    transition: background-color .3s;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border-bottom: 2px outset #29aae1;
    margin: 0 3px;
    
    //border-radius: 20px;
}

.page a:hover:not(.active) {
    //background-color: $primary;
    color: #29aae1;
    border-bottom: 3px solid #29aae1;
    font-size: 20px;
    font-weight: 300;
    
    cursor: pointer;
}

/*.pagination .current-page a {
    border-color: $secondary;
    color: black;
}*/

@media screen and (max-width:576px){
}
@media screen and (min-width:1440px){
}
@media screen and (min-width:2025px){
    .pagination .page-link {
        background-color: transparent;
        padding: 6px 11px;
        color: #9ca0a3;
        float: left;
        text-decoration: none;
        font-size: 28px;
        font-weight: 100;
        font-family: 'Montserrat', sans-serif;
        transition: background-color .3s;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        border-bottom: 2px outset #29aae1;
        margin: 0 3px;
        
        //border-radius: 20px;
    }
    
    .page a:hover:not(.active) {
        //background-color: $primary;
        color: #29aae1;
        border-bottom: 3px solid #29aae1;
        font-size: 28px;
        font-weight: 500;
        
        cursor: pointer;
    }
    
}
@media screen and (min-width:2700px){
    .pagination .page-link {
        background-color: transparent;
        padding: 6px 11px;
        color: #9ca0a3;
        float: left;
        text-decoration: none;
        font-size: 42px;
        font-weight: 100;
        font-family: 'Montserrat', sans-serif;
        transition: background-color .3s;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        border-bottom: 2px outset #29aae1;
        margin: 0 3px;
        
        //border-radius: 20px;
    }
    
    .page a:hover:not(.active) {
        //background-color: $primary;
        color: #29aae1;
        border-bottom: 3px solid #29aae1;
        font-size: 42px;
        font-weight: 500;
        
        cursor: pointer;
    }
    
}
