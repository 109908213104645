.footer {
	display: flex !important;
    position: relative;
    background-color: #242a38;
    padding: 10px ;
    z-index: 1;
    justify-content: center;
    align-items: center;

	span {
		padding: 10px;
	}
}

@media screen and (max-width: 568px) {
    .footer {
        padding: 0 10px;
    }
}

@media screen and (min-width: 2698px) {
    .footer {
        span {
            padding: 10px 24px;

            a {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (min-width: 4045px) {
    .footer {
        span {
            padding: 10px 32px;

            a {
                font-size: 3rem;
            }
        }
    }
}