
    .principal-card {
        /*width: 100%;
        max-width: 1200px;
        height: 430px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;*/
    
        position: relative !important;
        border-radius: 4px !important;
        background-color: transparent !important; 
        border: 1px solid transparent !important;
        overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
        cursor: pointer !important;  
        box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
        text-align: center !important;
        transform-style: preserve-3d !important; 
        -webkit-transition: -webkit-transform .2s ease !important;
        transition: -webkit-transform .2s ease !important;
        transition: transform .2s ease !important;
        transition: transform .2s ease,-webkit-transform .2s ease !important;
        height: 374px/*170px*/ ;
        width: 100%;
        background-size: 16.4rem ;
        background-repeat: no-repeat !important;
        background-position: 50% !important;
        max-width: 100% !important;
    
        
            // background-repeat: no-repeat !important;
            // background-position: center !important;
            // background-size: contain !important; //Con esto debe adaptarse
            // height: 295px !important; //Y con esto también, porque ya le estás diciendo que hay un tamaño por default 
            // width: 100% !important;
           
            
            /*background-color:white;
            width: 330px;
            height: 430px;
            border-radius: 8px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            margin: 20px; 
            text-align: center;
            transition: all 0.25s;*/
    
            /*img{
                width: 330px;
                height: 220px;
            }*/
    
            // img {
            //     border-top-left-radius: 4px !important;
            //     border-top-right-radius: 4px !important;
            //     -webkit-transition: -webkit-transform .2s ease !important;
            //     transition: -webkit-transform .2s ease !important;
            //     transition: transform .2s ease !important;
            //     transition: transform .2s ease,-webkit-transform .2s ease !important;
            //     height: 390px/*170px*/ !important;
            //     width: 100% !important;
            //     overflow: hidden !important;
            //     background-size: 17rem !important;
            //     background-repeat: no-repeat !important;
            //     background-position: 50% !important;
            //     max-width: 100% !important;
            //     border: transparent;
                
    
              
            // }
        
    
        //Aquí ya le metes tú lo que quieras para que se vea bonis
        &:hover{
            transform: translateY(-5px) !important;
            box-shadow: 0 15px 20px #030303 !important;
            filter: opacity(.6) !important;
            
        }
    }

    .imagen-producto-home img {
        &:hover{
            transform: translateY(-5px) !important;
            box-shadow: 0 15px 20px #030303 !important;
            filter: opacity(.6) !important;
        }
    }

// dispositivos móviles    

    @media screen and (min-width:318px){   
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 366px/*170px*/ ;
            width:98% ;
            background-size: 16rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    } 
    @media screen and (min-width:360px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 434px/*170px*/ ;
            width:100%;
            background-size: 19rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;       
            }
        }   
    }
    @media only screen and (min-width: 393px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 455px/*170px*/ ;
            width: 100% ;
            background-size: 20rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    }
    @media screen and (min-width:411px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 480px/*170px*/ ;
            width:100%;
            background-size: 21rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;       
            }
        }   
    }
    @media screen and (min-width:768px){
   
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 297px/*170px*/ ;
            width:100%;
            background-size: 13rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
        &:hover{
            transform: translateY(-5px) !important;
            box-shadow: 0 15px 20px #030303 !important;
            filter: opacity(.6) !important;       
        }
    }   
    }
    @media screen and (min-width:1340px){
    .principal-card {
        /*width: 100%;
        max-width: 1200px;
        height: 430px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;*/
    
        position: relative !important;
        border-radius: 4px !important;
        background-color: transparent !important; 
        border: 1px solid transparent !important;
        overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
        cursor: pointer !important;  
        box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
        text-align: center !important;
        transform-style: preserve-3d !important; 
        -webkit-transition: -webkit-transform .2s ease !important;
        transition: -webkit-transform .2s ease !important;
        transition: transform .2s ease !important;
        transition: transform .2s ease,-webkit-transform .2s ease !important;
        height: 374px/*170px*/ ;
        width: 100%;
        background-size: 16.4rem ;
        background-repeat: no-repeat !important;
        background-position: 50% !important;
        max-width: 100% !important;
    
        
            // background-repeat: no-repeat !important;
            // background-position: center !important;
            // background-size: contain !important; //Con esto debe adaptarse
            // height: 295px !important; //Y con esto también, porque ya le estás diciendo que hay un tamaño por default 
            // width: 100% !important;
           
            
            /*background-color:white;
            width: 330px;
            height: 430px;
            border-radius: 8px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
            overflow: hidden;
            margin: 20px; 
            text-align: center;
            transition: all 0.25s;*/
    
            /*img{
                width: 330px;
                height: 220px;
            }*/
    
            // img {
            //     border-top-left-radius: 4px !important;
            //     border-top-right-radius: 4px !important;
            //     -webkit-transition: -webkit-transform .2s ease !important;
            //     transition: -webkit-transform .2s ease !important;
            //     transition: transform .2s ease !important;
            //     transition: transform .2s ease,-webkit-transform .2s ease !important;
            //     height: 390px/*170px*/ !important;
            //     width: 100% !important;
            //     overflow: hidden !important;
            //     background-size: 17rem !important;
            //     background-repeat: no-repeat !important;
            //     background-position: 50% !important;
            //     max-width: 100% !important;
            //     border: transparent;
                
    
              
            // }
        
    
        //Aquí ya le metes tú lo que quieras para que se vea bonis
        &:hover{
            transform: translateY(-5px) !important;
            //background-color: rgb(0, 0, 0) !important;
            box-shadow: 0 15px 20px #030303 !important;
            filter: opacity(.6) !important;
            
        }
    }
    }
// pantallas largas
    @media screen and (min-width:1490px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 418px/*170px*/ ;
            width: 100% ;
            background-size: 18.3rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    }
    @media screen and (min-width:1690px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 479px/*170px*/ ;
            width: 100% ;
            background-size: 21rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    }
    @media screen and (min-width:1800px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 505px/*170px*/ ;
            width: 100% ;
            background-size: 22.1rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    }
    @media screen and (min-width:2025px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 570px/*170px*/ ;
            width: 100% ;
            background-size: 25rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    }
    @media screen and (min-width:2700px){
        .principal-card {
            position: relative !important;
            border-radius: 4px !important;
            background-color: transparent !important; 
            border: 1px solid transparent !important;
            overflow:hidden !important; //Esto lo que hace es esconder lo que se salga del cuadro.
            cursor: pointer !important;  
            box-shadow:  0 4px 6px 0 rgba(7, 7, 7, 0.336), 0 6px 20px 0 rgba(0, 0, 0, 0.329) !important;
            text-align: center !important;
            transform-style: preserve-3d !important; 
            -webkit-transition: -webkit-transform .2s ease !important;
            transition: -webkit-transform .2s ease !important;
            transition: transform .2s ease !important;
            transition: transform .2s ease,-webkit-transform .2s ease !important;
            height: 776px/*170px*/ ;
            width: 100% ;
            background-size: 34rem;
            background-repeat: no-repeat !important;
            background-position: 50% !important;
            max-width: 100% !important;
        
            &:hover{
                transform: translateY(-5px) !important;
                box-shadow: 0 15px 20px #030303 !important;
                filter: opacity(.6) !important;
                
            }
        }
    }

@media screen and (min-width:1440px){   
}
@media screen and (min-width:411px) and (max-width:823px){  
}
@media screen and (min-width:736px){
}

