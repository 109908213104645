:root{
    --color-primario: white; 
    --color-secundario: #153e52;
    --color-terciario:#eeebebab;
    --color-titulos: #14bf98;
    --menu:  transparent;
    --menu-letra: white;
    --menu-hover: #14bf98;
    --boton:  #14bf98;
    --letra-btn: white;
    --iconos:  white;
    --iconos-hover:#14bf98 ; 
}
.btn{
    background-color: var(--boton);
    color: var(--letra-btn);
}
.color-icons{
    color: var(--iconos);
}

.header {
    width: 100%;
    .column {
        width: 100% !important;

        .home{
            min-height: 700px;
            background-position: center;
            background-size:cover;
            background-color: #000000;
            //background: linear-gradient(358deg, #ffffff 0%, #702d2c 0%, #ffffff 0%, #ffffff 0%, #a24e4e 100%);
            background-repeat: repeat, no-repeat; 
            // background-image: linear-gradient(180deg,rgba(0, 0, 0, 0.35), #141414a1),  url("https://images03.nicepage.io/a1389d7bc73adea1e1c1fb7e/86fe1ae2810f540f979a6f85/pexelsphoto3404200.jpeg");
            
            .logoAppm{
                background-color: #3d3d3d00;
                width: 100% !important;
                .logoApp{
                    width: 95%;
                    .logoImg{
                        width: 14% ;
                        height: auto;
                    }
                }
            }   
            .menu{
                width: 100%;
                height: auto;
                position: relative;
                padding-top: 100px;
                padding-bottom: 65px;
                .leftLine{
                    width: 50% !important;
                    height: 350px;
                    // background-color: #1e2a39de;
                    .infoContact{
                        color: red;
                        width: 100%;
                        .titulo3{
                            color: #ebebeb;
                            text-align: center;
                            width: 95%;
                            font-weight: 300;
                            font-family: 'Montserrat', sans-serif;
                            padding-bottom: 75px;
                          
            
                            i{
                                font-size: 2.5em;
                                color:  #1d2835;
                                padding-right: 1rem;
                            }
                            .tel{
                                color: #dcdcdc;
                                text-align: center;
                                font-size: 23px;
                                font-weight: 100;
                                font-family: 'Montserrat', sans-serif;
                                   
                            }
                        }
                    }
                    .leftLines{
                        height: 40px;
                        background-color: #1e2a39de;
                        width: 100%;
                    }
                    .whiteSpace{
                        width: 100%;
                    }
                }
                .menuLogo{
                    width: 100%;
                    // height: 210px;
                    // background: rgb(185 140 140);
                    border-radius: 3px;

                    
                    .logo{
                        width: 60%;
                        .tamaño{
                            width: 350px;
                            height: 350px;
                            background-size: auto;
                            border-radius: 244px;
                            border: 1px solid red;
                            position: cover;
                            border: 3px solid #1e2a39de;
                            // border: 3px solid #686868;
                           
                   
                        }
                    }
                }
                .rightLine{
                    width: 50% !important;
                    height: 350px;
                    // background-color: #1e2a39de;
                    .infoContact{
                        color: red;
                        width: 100%;
                        .titulo3{
                            color: #ebebeb;
                            text-align: center;
                            width: 95%;
                            font-weight: 300;
                            font-family: 'Montserrat', sans-serif;
                            padding-bottom: 75px;
                          
            
                            i{
                                font-size: 2.5em;
                                color:  #1d2835;
                                padding-right: 1rem;
                            }
                            .tel{
                                color: #dcdcdc;
                                text-align: center;
                                font-size: 23px;
                                font-weight: 100;
                                font-family: 'Montserrat', sans-serif;
                                   
                            }
                        }
                    }
                    .rightLines{
                        height: 40px;
                        background-color: #1e2a39de;
                        width: 100%;
                    }
                    .whiteSpace{
                        width: 100%;
                    }
                    
                }
                
            }
            .tHeader{
                width: 70%;
                margin: 20px 0;
                padding: 0 15%;
                text-align: center;
                
                .t1{
                    
                    margin-bottom: 27px;
                    font-family: "Montserrat", sans-serif;
                    color: rgb(14, 14, 14);
                    font-size: 48px;
                    line-height: 53px;
                    font-weight: 400;
                    
                }
                .t2{
                    margin-bottom: 24px;
                    font-family: "Montserrat", sans-serif;
                    font-size: 52px;
                    line-height: 68px;
                    font-weight: 300;
                    letter-spacing: 2px;
                    color: #ececec;
                    /* background-color: red; */
                    text-align: center;
                    // -webkit-background-clip: text;
                    // background-clip: text;
                    // -webkit-text-fill-color: transparent;
                }
                .buttn{
                    border: 1px solid #a77878;
                    border-radius: 3px;
                    padding: 9px 15px;
                    background-color: transparent;
                    color: #a77878;
                    
                    &:hover{
                        color: #999 ;
                        border: 1px solid #999;
                        
                    }
                    .buttn2{
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 300;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}


// dispositivos-----------------------------------
@media screen and (max-width:1024px){
    .header {
        width: 100%;
        .column {
            width: 100% !important;
            .home{
                min-height: 700px;
                background-position: center;
                background-size:cover;
                background-color: #000000;
                //background: linear-gradient(358deg, #ffffff 0%, #702d2c 0%, #ffffff 0%, #ffffff 0%, #a24e4e 100%);
                background-repeat: repeat, no-repeat; 
                // background-image: linear-gradient(180deg,rgba(0, 0, 0, 0.35), #141414a1),  url("https://images03.nicepage.io/a1389d7bc73adea1e1c1fb7e/86fe1ae2810f540f979a6f85/pexelsphoto3404200.jpeg");
                
                .logoAppm{
                    background-color: #3d3d3d00;
                    width: 100% !important;
                    .logoApp{
                        width: 95%;
                        .logoImg{
                            width: 26% ;
                            height: auto;
                        }
                    }
                }   
                .menu{
                    width: 100%;
                    height: auto;
                    position: relative;
                    padding-top: 100px;
                    padding-bottom: 65px;
                    .leftLine{
                        width: 50% !important;
                        height: 188px;
                        // background-color: #1e2a39de;
                        .infoContact{
                            color: red;
                            width: 100%;
                            .titulo3{
                                color: #ebebeb;
                                text-align: center;
                                width: 95%;
                                font-weight: 300;
                                font-family: 'Montserrat', sans-serif;
                                padding-bottom: 12px;
                              
                
                                i{
                                    font-size: 2.5em;
                                    color:  #1d2835;
                                    padding-right: 1rem;
                                }
                                .tel{
                                    color: #dcdcdc;
                                    text-align: center;
                                    font-size: 23px;
                                    font-weight: 100;
                                    font-family: 'Montserrat', sans-serif;
                                       
                                }
                            }
                        }
                        .leftLines{
                            height: 40px;
                            background-color: #1e2a39de;
                            width: 100%;
                        }
                        .whiteSpace{
                            width: 100%;
                        }
                    }
                    .menuLogo{
                        width: 100%;
                        // height: 210px;
                        // background: rgb(185 140 140);
                        border-radius: 3px;
    
                        
                        .logo{
                            width: 60%;
                            .tamaño{
                                width: 260px;
                                height: auto;
                                background-size: auto;
                                border-radius: 244px;
                                border: 1px solid red;
                                position: cover;
                                border: 3px solid #1e2a39de;
                                // border: 3px solid #686868;
                               
                       
                            }
                        }
                    }
                    .rightLine{
                        width: 50% !important;
                        height: 193px;
                        // background-color: #1e2a39de;
                        .infoContact{
                            color: red;
                            width: 100%;
                            .titulo3{
                                color: #ebebeb;
                                text-align: center;
                                width: 95%;
                                font-weight: 300;
                                font-family: 'Montserrat', sans-serif;
                                padding-top: 55px;
                                padding-bottom: 10px;
                              

                                i{
                                    font-size: 2.5em;
                                    color:  #1d2835;
                                    padding-right: 1rem;
                                }
                                .tel{
                                    color: #dcdcdc;
                                    text-align: center;
                                    font-size: 23px;
                                    font-weight: 100;
                                    font-family: 'Montserrat', sans-serif;
                                       
                                }
                            }
                        }
                        .rightLines{
                            height: 40px;
                            background-color: #1e2a39de;
                            width: 100%;
                        }
                        .whiteSpace{
                            width: 100%;
                        }
                        
                    }
                    
                }
                .tHeader{
                    width: 70%;
                    margin: 20px 0;
                    padding: 0 15%;
                    text-align: center;
                    
                    .t1{
                        
                        margin-bottom: 27px;
                        font-family: "Montserrat", sans-serif;
                        color: rgb(14, 14, 14);
                        font-size: 48px;
                        line-height: 53px;
                        font-weight: 400;
                        
                    }
                    .t2{
                        margin-bottom: 24px;
                        font-family: "Montserrat", sans-serif;
                        font-size: 52px;
                        line-height: 68px;
                        font-weight: 300;
                        letter-spacing: 2px;
                        color: #ececec;
                        /* background-color: red; */
                        text-align: center;
                        // -webkit-background-clip: text;
                        // background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }
                    .buttn{
                        border: 1px solid #a77878;
                        border-radius: 3px;
                        padding: 9px 15px;
                        background-color: transparent;
                        color: #a77878;
                        
                        &:hover{
                            color: #999 ;
                            border: 1px solid #999;
                            
                        }
                        .buttn2{
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 300;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:560px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 65px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 100%;
                    // .izquierda{
                    //     width: 100%;
                    //     .logo1 {
                    //         width: 18.5%;
                    //         height: 90px;
                            
                    //     }
                    // } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 130px;
                        height: auto;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 14px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                            font-size: 11px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 55%;
                        height: auto;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 100%;
                padding-top: 4rem;
                .cont{
                    width: 100%;
                    .h1{
                        color: var(--color-primario);
                        width: 100%;
                        text-align: end;
                        font-size: 1.2rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29aae1;
                            font-size: 1.2rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 100%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 1rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 100%;
                    .tele{
                       width: 9%;
                       padding: 0 6px;
                        a{
                           font-size: 13px;
                        i{
                            color:#a5a6a8 ;
                        }
                       }
                      
                    }
                    .tel{
                        width: 27%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 10px;
                    }
                    .email{
                        width: 41%;
                        color: #a5a6a8;
                        font-size: 10px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    //#a5a6a8
                    //#45464a
                }
            }
        }
    }
}
@media screen and (max-width:640px){
    .header {
        width: 100%;
        .column {
            width: 100% !important;
    
            .home{
                min-height: 700px;
                background-position: center;
                background-size:cover;
                background-color: #000000;
                //background: linear-gradient(358deg, #ffffff 0%, #702d2c 0%, #ffffff 0%, #ffffff 0%, #a24e4e 100%);
                background-repeat: repeat, no-repeat; 
                // background-image: linear-gradient(180deg,rgba(0, 0, 0, 0.35), #141414a1),  url("https://images03.nicepage.io/a1389d7bc73adea1e1c1fb7e/86fe1ae2810f540f979a6f85/pexelsphoto3404200.jpeg");
                
                .logoAppm{
                    background-color: #3d3d3d00;
                    width: 100% !important;
                    .logoApp{
                        width: 95%;
                        .logoImg{
                            width: 35% ;
                            height: auto;
                        }
                    }
                }   
                .menu{
                    width: 100%;
                    height: auto;
                    position: relative;
                    padding-top: 50px;
                    padding-bottom: 0px;
                    .leftLine{
                        width: 50% !important;
                        height: 145px;
                        // background-color: #1e2a39de;
                        .infoContact{
                            color: red;
                            width: 100%;
                            .titulo3{
                                color: #ebebeb;
                                text-align: center;
                                width: 95%;
                                font-weight: 300;
                                font-family: 'Montserrat', sans-serif;
                                padding-bottom: 15px;
                              
                
                                i{
                                    font-size: 2.2em;
                                    color:  #344962;
                                    padding-right: 1rem;
                                }
                                .tel{
                                    color: #dcdcdc;
                                    text-align: center;
                                    font-size: 17px;
                                    font-weight: 100;
                                    font-family: 'Montserrat', sans-serif;
                                       
                                }
                            }
                        }
                        .leftLines{
                            height: 20px;
                            background-color: #344962;
                            width: 100%;
                        }
                        .whiteSpace{
                            width: 100%;
                        }
                    }
                    .menuLogo{
                        width: 100%;
                        // height: 210px;
                        // background: rgb(185 140 140);
                        border-radius: 3px;
    
                        
                        .logo{
                            width: 70%;
                            .tamaño{
                                width: 200px;
                                height: 200px;
                                background-size: auto;
                                border-radius: 244px;
                                border: 1px solid red;
                                position: cover;
                                border: 3px solid #344962;
                                // border: 3px solid #686868;
                               
                       
                            }
                        }
                    }
                    .rightLine{
                        width: 50% !important;
                        height: 185px;
                        padding-top: 0rem;

                        // background-color: #1e2a39de;
                        .infoContact{
                            color: red;
                            width: 100%;
                            .titulo3{
                                color: #ebebeb;
                                text-align: center;
                                width: 95%;
                                font-weight: 300;
                                font-family: 'Montserrat', sans-serif;
                                padding-bottom: 15px;
                              
                
                                i{
                                    font-size: 2.2em;
                                    color:  #344962;
                                    padding-right: 1rem;
                                }
                                .tel{
                                    color: #dcdcdc;
                                    text-align: center;
                                    font-size: 17px;
                                    font-weight: 100;
                                    font-family: 'Montserrat', sans-serif;
                                       
                                }
                            }
                        }
                        .rightLines{
                            height: 20px;
                            background-color: #344962;
                            width: 100%;
                        }
                        .whiteSpace{
                            width: 100%;
                        }
                        
                    }
                    
                }
                .tHeader{
                    width: 70%;
                    margin: 0px 0;
                    padding: 0 15%;
                    text-align: center;
                    
                    .t1{
                        
                        margin-bottom: 27px;
                        font-family: "Montserrat", sans-serif;
                        color: rgb(14, 14, 14);
                        font-size: 48px;
                        line-height: 53px;
                        font-weight: 400;
                        
                    }
                    .t2{
                        margin-bottom: 45px;
                        font-family: "Montserrat", sans-serif;
                        font-size: 22px;
                        line-height: 42px;
                        font-weight: 300;
                        letter-spacing: 1px;
                        color: #ececec;
                        /* background-color: red; */
                        text-align: center;
                        // -webkit-background-clip: text;
                        // background-clip: text;
                        // -webkit-text-fill-color: transparent;
                    }
                    .buttn{
                        border: 1px solid #a77878;
                        border-radius: 3px;
                        padding: 9px 15px;
                        background-color: transparent;
                        color: #a77878;
                        
                        &:hover{
                            color: #999 ;
                            border: 1px solid #999;
                            
                        }
                        .buttn2{
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 300;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }    
}
@media screen and (max-width:665px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 65px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 100%;
                    // .izquierda{
                    //     width: 100%;
                    //     .logo1 {
                    //         width: 18.5%;
                    //         height: 90px;
                            
                    //     }
                    // } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 130px;
                        height: auto;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 14px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                            font-size: 11px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 38%;
                        height: auto;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 100%;
                padding-top: 4rem;
                .cont{
                    width: 100%;
                    .h1{
                        color: var(--color-primario);
                        width: 100%;
                        text-align: end;
                        font-size: 1.5rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29aae1;
                            font-size: 1.5rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 100%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 1.1rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 100%;
                    .tele{
                       width: 9%;
                       padding: 0 6px;
                        a{
                           font-size: 13px;
                        i{
                            color:#a5a6a8 ;
                        }
                       }
                      
                    }
                    .tel{
                        width: 26%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 10px;
                    }
                    .email{
                        width: 42%;
                        color: #a5a6a8;
                        font-size: 10px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    //#a5a6a8
                    //#45464a
                }
            }
        }
    }
}



@media screen and (min-width:1499px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 72px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 43%;
                        // height: 64px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 24px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 98px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 2.48rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29abe2;
                            font-size: 2.48rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 2.79rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 7%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 13px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 13px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1690px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 81px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 39.5%;
                        // height: 73px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 28px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 115px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 2.8rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29abe2;
                            font-size: 2.8rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 3.18rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 7%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 13px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 13px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1800px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 87px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 38.5%;
                        // height: 81px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 33px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 121px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 3rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29abe2;
                            font-size: 3rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 3.4rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 6.5%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 16px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 16px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:2025px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 98px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 40.5%;
                        // height: 88px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 33px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                            font-size: 20px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 136px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 3.4rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29abe2;
                            font-size: 3.4rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 3.83rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 6.5%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 18px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 18px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:2700px){   
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 129px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 41.5%;
                        // height: 112px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 41px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #29aae1;
                            font-weight: 100;
                            font-size: 28px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 178px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 4.5rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            color: #29abe2;
                            font-size: 4.5rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 5.2rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #29abe2 ;
                }
                .red{
                    width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                           font-size: 26px;
                       }
                    }
                    .tel{
                        width: 6.5%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 24px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 24px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
// @media screen and (min-width:1230px) and (max-width:2500px){
    
//     .header {
//         width: 100%;
    
//         .column {
//             width: 100%;
//         }
//         .content-header{ 
//             width: 100%;
//             height: 65px;
//             background-color: #242a38;
            
//             position: relative;
//             top: 0; 
//             z-index: 1;
//             right: 0;
//             left: 0;
//             padding: 0rem 0;
//             //background-image: linear-gradient(
//                // #113448,
//                // #113448
//                //   ),; pon el koala
//             .container{
//                 width: 90%;
//                 .izquierda{
//                     width: 100%;
//                     .logo1 {
//                         width: 18.5%;
//                         height: 90px;
                        
//                     }
//                 } 
//                 .centro{
//                     width: 100%;
                  
//                     .logo2 {
//                         width: 45%;
//                         height: 58px;
//                         justify-content: center;
//                         .lg{
//                             padding-right: 2px;
//                         }
//                         .prove{
//                             padding: 20px 0;
//                             font-family: 'Montserrat', sans-serif;
//                             color: #29aae1;
//                             font-weight: 100;
//                         }
                        
//                     } 
//                 }
//                 .derecha{
//                     width: 100%;
//                     .logo3 {
//                         width: 27.5%;
//                         height: 90px;
                        
//                     }
//                 }    
//             }
//         } 
//         .titulo{
//             width: 100%;
//             padding: 2rem;
           
       
    
//             background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
//             url(/img/Banner.jpg); 
//             background-size: cover;
//             background-position: start;
          
//             height: 103vh;
            
//             .column{
//                 width: 95%;
//                 padding-top: 4rem;
//                 .cont{
//                     width: 80%;
//                     .h1{
//                         color: var(--color-primario);
//                         width: 47%;
//                         text-align: end;
//                         font-size: 2.2rem;
//                         font-weight: 300;
//                         font-family: 'Montserrat', sans-serif;
//                         letter-spacing: 1.5px;
//                         .patro{
//                             color: #29aae1;
//                             font-size: 2.2rem;
//                             font-weight: 400;
//                             font-family: 'Montserrat', sans-serif;
//                             text-shadow: 0.1em 0.1em 0.2em black;
//                         }
//                     }
//                 }
               
//                 .h2{
//                     color: var(--color-primario);
//                     width: 77%;
//                     padding-bottom: 4px;
//                     text-align: center;
//                     letter-spacing: 2px;
//                     font-size: 2.48rem;
//                     text-shadow: 0.1em 0.1em 0.4em black;
//                     font-weight: 600;
//                     font-family: 'Montserrat', sans-serif;
//                     border-bottom: 3px solid #29abe2 ;
//                 }
//                 .red{
//                     width: 110%;
//                     .tele{
//                        width: 2%;
//                        padding: 0 6px;
//                        i{
//                            color:#a5a6a8 ;
//                        }
//                     }
//                     .tel{
//                         width: 7%;
//                         border-right:1px solid #a5a6a8 ;
//                         color: #a5a6a8;
//                         font-family: 'Montserrat', sans-serif;
//                         font-size: 13px;
//                     }
//                     .email{
//                         width: 13%;
//                         color: #a5a6a8;
//                         font-size: 13px;
//                         font-family: 'Montserrat', sans-serif;
//                     }
//                     //#a5a6a8
//                     //#45464a
//                 }
//             }
//         }
//     }
    
// }




