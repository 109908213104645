:root{
    --color-primario: white; 
    --color-secundario: #153e52;
    --color-terciario:#eeebebab;
    --color-titulos: #14bf98;
    --menu:  transparent;
    --menu-letra: white;
    --menu-hover: #14bf98;
    --boton:  #14bf98;
    --letra-btn: white;
    --iconos:  white;
    --iconos-hover:#14bf98 ; 
}
.btn{
    background-color: var(--boton);
    color: var(--letra-btn);
}
.color-icons{
    color: var(--iconos);
}

.header {
    width: 100%;

    .column {
        width: 100% !important;
    }
    .content-header{ 
        width: 100% !important;
        // height: 65px;
        background-color: #242a38;
        
        // position: relative;
        // top: 0; 
        // z-index: 1;
        // right: 0;
        // left: 0;
        // padding: 0rem 0;
        //background-image: linear-gradient(
           // #113448,
           // #113448
           //   ),; pon el koala
        .container{
            width: 90% ;
            .izquierda{
                width: 100%;
                .logo1 {
                    width: 18.5% ;
                    height: auto;
                    
                }
            } 
            .centro{
                width: 100% ;
              
                .logo2 {
                    width: 45.5% ;
                    height: auto;
                    justify-content: center;
                    .lg{
                        padding-right: 2px;
                    }
                    .prove{
                        padding: 20px 0;
                        font-family: 'Montserrat', sans-serif;
                        color: #ff3980;
                        font-weight: 100;
                    }
                    
                } 
            }
            .derecha{
                width: 100% !important;
                .logo3 {
                    width: 17% ;
                    height: auto;
                    
                }
            }    
        }
    } 
    .titulo{
        width: 100%;
        padding: 2rem;
        background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
        url(/img/Banner.jpg); 
        background-size: cover;
        background-position: start;
        height: 103vh;
        
        .column{
            width: 95%;
            padding-top: 4rem;
            .cont{
                width: 80%;
                .h1{
                    color: var(--color-primario);
                    width: 47%;
                    text-align: end;
                    font-size: 2.2rem;
                    font-weight: 300;
                    font-family: 'Montserrat', sans-serif;
                    letter-spacing: 1.5px;
                    .patro{
                        color: #ff3980;
                        font-size: 2.2rem;
                        font-weight: 400;
                        font-family: 'Montserrat', sans-serif;
                        text-shadow: 0.1em 0.1em 0.2em black;
                    }
                }
            }
           
            .h2{
                color: var(--color-primario);
                width: 77%;
                padding-bottom: 4px;
                text-align: center;
                letter-spacing: 2px;
                font-size: 2.48rem;
                text-shadow: 0.1em 0.1em 0.4em black;
                font-weight: 600;
                font-family: 'Montserrat', sans-serif;
                border-bottom: 3px solid #ff3980 ;
            }
            .red{
                width: 100%;
                .tele{
                   //width: 2%;
                   padding: 0 6px;
                   i{
                       color:#a5a6a8 ;
                   }
                }
                .tel{
                    width: 7%;
                    border-right:1px solid #a5a6a8 ;
                    color: #a5a6a8;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 13px;
                }
                .email{
                    width: 13%;
                    color: #a5a6a8;
                    font-size: 13px;
                    font-family: 'Montserrat', sans-serif;
                }
                //#a5a6a8
                //#45464a
            }
        }
    }
}




// @media screen and (min-width:1230px) and (max-width:2500px){
    
//     .header {
//         width: 100%;
    
//         .column {
//             width: 100%;
//         }
//         .content-header{ 
//             width: 100%;
//             height: 65px;
//             background-color: #242a38;
            
//             position: relative;
//             top: 0; 
//             z-index: 1;
//             right: 0;
//             left: 0;
//             padding: 0rem 0;
//             //background-image: linear-gradient(
//                // #113448,
//                // #113448
//                //   ),; pon el koala
//             .container{
//                 width: 90%;
//                 .izquierda{
//                     width: 100%;
//                     .logo1 {
//                         width: 18.5%;
//                         height: 90px;
                        
//                     }
//                 } 
//                 .centro{
//                     width: 100%;
                  
//                     .logo2 {
//                         width: 45%;
//                         height: 58px;
//                         justify-content: center;
//                         .lg{
//                             padding-right: 2px;
//                         }
//                         .prove{
//                             padding: 20px 0;
//                             font-family: 'Montserrat', sans-serif;
//                             color: #ff3980;
//                             font-weight: 100;
//                         }
                        
//                     } 
//                 }
//                 .derecha{
//                     width: 100%;
//                     .logo3 {
//                         width: 27.5%;
//                         height: 90px;
                        
//                     }
//                 }    
//             }
//         } 
//         .titulo{
//             width: 100%;
//             padding: 2rem;
           
       
    
//             background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
//             url(/img/Banner.jpg); 
//             background-size: cover;
//             background-position: start;
          
//             height: 103vh;
            
//             .column{
//                 width: 95%;
//                 padding-top: 4rem;
//                 .cont{
//                     width: 80%;
//                     .h1{
//                         color: var(--color-primario);
//                         width: 47%;
//                         text-align: end;
//                         font-size: 2.2rem;
//                         font-weight: 300;
//                         font-family: 'Montserrat', sans-serif;
//                         letter-spacing: 1.5px;
//                         .patro{
//                             color: #ff3980;
//                             font-size: 2.2rem;
//                             font-weight: 400;
//                             font-family: 'Montserrat', sans-serif;
//                             text-shadow: 0.1em 0.1em 0.2em black;
//                         }
//                     }
//                 }
               
//                 .h2{
//                     color: var(--color-primario);
//                     width: 77%;
//                     padding-bottom: 4px;
//                     text-align: center;
//                     letter-spacing: 2px;
//                     font-size: 2.48rem;
//                     text-shadow: 0.1em 0.1em 0.4em black;
//                     font-weight: 600;
//                     font-family: 'Montserrat', sans-serif;
//                     border-bottom: 3px solid #ff3980 ;
//                 }
//                 .red{
//                     width: 110%;
//                     .tele{
//                        width: 2%;
//                        padding: 0 6px;
//                        i{
//                            color:#a5a6a8 ;
//                        }
//                     }
//                     .tel{
//                         width: 7%;
//                         border-right:1px solid #a5a6a8 ;
//                         color: #a5a6a8;
//                         font-family: 'Montserrat', sans-serif;
//                         font-size: 13px;
//                     }
//                     .email{
//                         width: 13%;
//                         color: #a5a6a8;
//                         font-size: 13px;
//                         font-family: 'Montserrat', sans-serif;
//                     }
//                     //#a5a6a8
//                     //#45464a
//                 }
//             }
//         }
//     }
    
// }

// dispositivos-----------------------------------
@media screen and (max-width: 320px) {
    .header {
        .titulo {
            .cont {
                width: 100% !important;
                .h1 {
                    font-size: 1.2rem !important;
                    width: 100% !important;

                    .patro {
                        font-size: 1.2rem !important;
                    }
                }
            }

            .h2 {
                font-size: 1rem !important;
                width: 100% !important;
            }

            .red {
                width: 100%;
                .tele{
                    width: 9% !important;
                    padding: 0 6px;
                    a{
                        font-size: 13px !important;
                    i{
                        color:#a5a6a8 ;
                    }
                    }
                    
                }
                .tel{
                    width: 27% !important;
                    border-right:1px solid #a5a6a8 ;
                    color: #a5a6a8;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 10px !important;
                }
                .email{
                    width: 50% !important;
                    color: #a5a6a8;
                    font-size: 10px !important;
                    font-family: 'Montserrat', sans-serif;
                }
                //#a5a6a8
                //#45464a
            }
        }
    }
}

@media screen and (min-width: 321px) and (max-width: 598px) {
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 65px;
            background-color: #242a38;
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;

            .container{
                width: 100%;

                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 130px;
                        height: auto;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 14px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                            font-size: 11px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 55%;
                        height: auto;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
            
            .column{
                width: 100%;
                padding-top: 4rem;
                .cont{
                    width: 100% !important;
                    .h1{
                        font-size: 1.2rem;
                        width: 100% !important;
                        .patro{
                            font-size: 1.2rem;
                        }
                    }
                }
               
                .h2{
                    font-size: 1rem;
                    width: 100% !important;
                }
                .red{
                    width: 100%;
                    .tele{
                       width: 9%;
                       padding: 0 6px;
                        a{
                           font-size: 13px;
                        i{
                            color:#a5a6a8 ;
                        }
                       }
                      
                    }
                    .tel{
                        width: 27%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 10px;
                    }
                    .email{
                        width: 41%;
                        color: #a5a6a8;
                        font-size: 10px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    //#a5a6a8
                    //#45464a
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width:1023px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                width: 85% !important;

                .h1 {
                    width: 100% !important;
                }
            }

            .h2 {
                width: 85% !important;
                font-size: 2.1rem !important;
            }
        }

        .titulo{
            width: 100%;
            padding: 2rem;
            
            .column{
                .red{
                    width: 95%;
                    .tele{
                       padding: 0 6px;
                        a{
                           font-size: 13px;
                        i{
                            color:#a5a6a8 ;
                        }
                       }
                      
                    }
                    .tel{
                        width: 14%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        //font-size: 10px;
                    }
                    .email{
                        width: 30%;
                        color: #a5a6a8;
                        //font-size: 10px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    //#a5a6a8
                    //#45464a
                }
            }
        }
    }
}

/*@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                .h1 {
                    width: 60% !important;
                }
            }

            .h2 {
                font-size: 1.8em !important;
            }
            
            .red {
                width: 100% !important;

                .tel {
                    width: 11% !important;
                    font-size: 15px !important;
                }

                .email {
                    width: 32% !important;
                    font-size: 15px !important;
                }
            }
        }
    }
}*/

@media screen and (min-width: 898px) and (max-width: 1078px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                //width: 100% !important;

                .h1 {
                    width: 50% !important;
                    font-size: 1.7rem !important;
                }

                .patro {
                    font-size: 1.7rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 1.7rem !important;
                //padding-top: 80px;
            }

            .red {
                .tel {
                    width: 8% !important;
                    font-size: 9px !important;
                }

                .email {
                    width: 12% !important;
                    font-size: 9px !important;
                }

                .tele {
                    a {
                        font-size: 0.9rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1079px) and (max-width: 1225px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                //width: 100% !important;

                .h1 {
                    width: 50% !important;
                    font-size: 1.8rem !important;
                }

                .patro {
                    font-size: 1.8rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 1.9rem !important;
                //padding-top: 80px;
            }

            .red {
                .tel {
                    font-size: 10px !important;
                }

                .email {
                    font-size: 10px !important;
                }

                .tele {
                    a {
                        font-size: 0.9rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1226px) and (max-width: 1349px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                //width: 100% !important;

                .h1 {
                    width: 50% !important;
                    font-size: 2.1rem !important;
                }

                .patro {
                    font-size: 2.1rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 2.2rem !important;
                padding-top: 80px;
            }

            .red {
                .tel {
                    font-size: 12px !important;
                }

                .email {
                    font-size: 12px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1686px) and (max-width: 1797px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                //width: 100% !important;

                .h1 {
                    width: 50% !important;
                    font-size: 2.5rem !important;
                }

                .patro {
                    font-size: 2.5rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 3rem !important;
                padding-top: 80px;
            }

            .red {
                .tel {
                    font-size: 16px !important;
                }

                .email {
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1798px) and (max-width: 2022px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                //width: 100% !important;

                .h1 {
                    width: 50% !important;
                    font-size: 2.8rem !important;
                }

                .patro {
                    font-size: 2.8rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 3.2rem !important;
                padding-top: 80px;
            }

            .red {
                .tel {
                    font-size: 16px !important;
                }

                .email {
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 2023px) and (max-width: 2697px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                width: 80% !important;

                .h1 {
                    width: 45% !important;
                    font-size: 3rem !important;
                }

                .patro {
                    font-size: 3rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 3.7rem !important;
                padding-top: 80px;
            }

            .red {
                //width: 100% !important;

                .tel {
                    font-size: 18px !important;
                }

                .email {
                    font-size: 18px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 2698px) {
    .header {
        width: 100%;
        
        .column {
            .cont {
                width: 80% !important;

                .h1 {
                    width: 43% !important;
                    font-size: 4rem !important;
                }

                .patro {
                    font-size: 4rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 4.9rem !important;
                    padding-top: 80px;
            }

            .red {
                //width: 100% !important;

                .tel {
                    width: 6% !important;
                    font-size: 23px !important;
                }

                .email {
                    font-size: 23px !important;
                }

                .tele {
                    a {
                        font-size: 1.2rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 4045px) {
     .header {
        width: 100%;
        
        .column {
            .cont {
                width: 80% !important;

                .h1 {
                    width: 43% !important;
                    font-size: 7rem !important;
                }

                .patro {
                    font-size: 7rem !important;
                }
            }

            .h2 {
                //width: 85% !important;
                font-size: 8rem !important;
                padding-top: 250px;
            }

            .red {
                //width: 100% !important;

                .tel {
                    width: 7% !important;
                    font-size: 38px !important;
                }

                .email {
                    width: 16% !important;
                    font-size: 38px !important;
                }

                .tele {
                    padding: 0 16px !important;

                    a {
                        font-size: 2.5rem !important;
                    }
                }
            }
        }
    }
}

/*@media screen and (max-width:640px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 65px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 100%;
                    // .izquierda{
                    //     width: 100%;
                    //     .logo1 {
                    //         width: 18.5%;
                    //         height: 90px;
                            
                    //     }
                    // } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 130px;
                        height: auto;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 14px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                            font-size: 11px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 45%;
                        height: auto;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 100%;
                padding-top: 4rem;
                .cont{
                    width: 100%;
                    .h1{
                        color: var(--color-primario);
                        width: 100%;
                        text-align: end;
                        font-size: 1.2rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 1.2rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 100%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 1rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    width: 100%;
                    .tele{
                       width: 9%;
                       padding: 0 6px;
                        a{
                           font-size: 13px;
                        i{
                            color:#a5a6a8 ;
                        }
                       }
                      
                    }
                    .tel{
                        width: 27%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 10px;
                    }
                    .email{
                        width: 41%;
                        color: #a5a6a8;
                        font-size: 10px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    //#a5a6a8
                    //#45464a
                }
            }
        }
    }
}
@media screen and (max-width:665px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 65px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 100%;
                    // .izquierda{
                    //     width: 100%;
                    //     .logo1 {
                    //         width: 18.5%;
                    //         height: 90px;
                            
                    //     }
                    // } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 130px;
                        height: auto;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 14px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                            font-size: 11px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 38%;
                        height: auto;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 100%;
                padding-top: 4rem;
                .cont{
                    width: 100%;
                    .h1{
                        color: var(--color-primario);
                        width: 100%;
                        text-align: end;
                        font-size: 1.5rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 1.5rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 100%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 1.1rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    width: 100%;
                    .tele{
                       width: 9%;
                       padding: 0 6px;
                        a{
                           font-size: 13px;
                        i{
                            color:#a5a6a8 ;
                        }
                       }
                      
                    }
                    .tel{
                        width: 26%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 10px;
                    }
                    .email{
                        width: 42%;
                        color: #a5a6a8;
                        font-size: 10px;
                        font-family: 'Montserrat', sans-serif;
                    }
                    //#a5a6a8
                    //#45464a
                }
            }
        }
    }
}

@media screen and (min-width:1499px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 72px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 43%;
                        // height: 64px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 24px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 98px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 2.48rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 2.48rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 2.79rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 7%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 13px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 13px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1690px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 81px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 39.5%;
                        // height: 73px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 28px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 115px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 2.8rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 2.8rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 3.18rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    // width: 100%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 7%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 13px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 13px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1800px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 87px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 38.5%;
                        // height: 81px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 33px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 121px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 3rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 3rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 3.4rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    // width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 6.5%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 16px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 16px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:2025px){
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 98px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 40.5%;
                        // height: 88px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 33px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                            font-size: 20px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 136px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 3.4rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 3.4rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 3.83rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    // width: 110%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                       }
                    }
                    .tel{
                        width: 6.5%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 18px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 18px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:2700px){   
    .header {
        width: 100%;
    
        .column {
            width: 100%;
        }
        .content-header{ 
            width: 100%;
            // height: 129px;
            background-color: #242a38;
            
            position: relative;
            top: 0; 
            z-index: 1;
            right: 0;
            left: 0;
            padding: 0rem 0;
            //background-image: linear-gradient(
               // #113448,
               // #113448
               //   ),; pon el koala
            .container{
                width: 90%;
                .izquierda{
                    width: 100%;
                    .logo1 {
                        width: 18.5%;
                        // height: 90px;
                        
                    }
                } 
                .centro{
                    width: 100%;
                  
                    .logo2 {
                        width: 41.5%;
                        // height: 112px;
                        justify-content: center;
                        .lg{
                            padding-right: 2px;
                        }
                        .prove{
                            padding: 41px 0;
                            font-family: 'Montserrat', sans-serif;
                            color: #ff3980;
                            font-weight: 100;
                            font-size: 28px;
                        }
                        
                    } 
                }
                .derecha{
                    width: 100%;
                    .logo3 {
                        width: 17%;
                        // height: 178px;
                        
                    }
                }    
            }
        } 
        .titulo{
            width: 100%;
            padding: 2rem;
           
       
    
            background-image: linear-gradient(rgb(14 15 18 / 61%), rgb(33 35 43 / 78%) 81%),
            url(/img/Banner.jpg); 
            background-size: cover;
            background-position: start;
          
            height: 103vh;
            
            .column{
                width: 95%;
                padding-top: 4rem;
                .cont{
                    width: 80%;
                    .h1{
                        color: var(--color-primario);
                        width: 47%;
                        text-align: end;
                        font-size: 4.5rem;
                        font-weight: 300;
                        font-family: 'Montserrat', sans-serif;
                        letter-spacing: 1.5px;
                        .patro{
                            font-size: 4.5rem;
                            font-weight: 400;
                            font-family: 'Montserrat', sans-serif;
                            text-shadow: 0.1em 0.1em 0.2em black;
                        }
                    }
                }
               
                .h2{
                    color: var(--color-primario);
                    width: 77%;
                    padding-bottom: 4px;
                    text-align: center;
                    letter-spacing: 2px;
                    font-size: 5.2rem;
                    text-shadow: 0.1em 0.1em 0.4em black;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    border-bottom: 3px solid #ff3980 ;
                }
                .red{
                    width: 100%;
                    .tele{
                       width: 2%;
                       padding: 0 6px;
                       i{
                           color:#a5a6a8 ;
                           font-size: 26px;
                       }
                    }
                    .tel{
                        width: 6.5%;
                        border-right:1px solid #a5a6a8 ;
                        color: #a5a6a8;
                        font-family: 'Montserrat', sans-serif;
                        font-size: 24px;
                    }
                    .email{
                        width: 13%;
                        color: #a5a6a8;
                        font-size: 24px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
            }
        }
    }
}*/




