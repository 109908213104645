.error404{
    display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: #000;
	flex-direction: column;
	overflow: hidden;

	h1 {
		color: #fff;
		font-size: 4rem;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
	h2 {
		color: #fff;
		margin-bottom: 10px;
	}
}