/**
*   fonts.scss
*   @description: Estilos de fuente y tipografía
*/

/*    Font families    */
    
$Roboto: 'Roboto', sans-serif;

/*    Font sizes    */

$fontMini:      0.8rem;
$fontTiny:      0.9rem;
$fontText:      0.93rem;
$fontSmall:     1rem;
$fontRegular:   1.2rem;
$fontMedium:    1.4rem;
$fontLarge:     1.6rem;
$fontHuge:      1.8rem;
$fontDouble:    2rem;
$fontTriple:    3rem;

.font-huge { font-size: $fontHuge; }

.font-medium { font-size: $fontMedium; }

.font-regular { font-size: $fontRegular; }

.font-small { font-size: $fontSmall; }

.font-text { font-size: $fontText; }

.font-tiny { font-size: $fontTiny; }

.font-mini { font-size: $fontMini; }


/*    Font weight   */

$weightRegular: 400;
$weightSemi: 500;
$weightBold: 700;
$weightMBold: 900;


.weight-mbold { font-weight: $weightMBold; }

.weight-bold { font-weight: $weightBold; }

.weight-semi { font-weight: $weightSemi; }

.weight-regular { font-weight: $weightRegular; }

a {
    font-family: $Roboto;
    font-size: $fontSmall;
}

h1, h2, h3, h4, h5, h6, p {
    color: $black;
    font-family: $Roboto;
    font-display: fallback;
}

h1 {
    font-size: $fontHuge;
    font-weight: $weightBold;
}

h2 {
    font-size: $fontLarge;
    font-weight: $weightBold;
}

h3 {
    font-size: $fontMedium;
    font-weight: $weightSemi;
}

h4 {
    font-size: $fontRegular;
    font-weight: $weightSemi;
}

h5 {
    font-size: $fontText;
    font-weight: $weightRegular;
}

h6 {
    font-size: $fontMedium;
    font-weight: $weightRegular;
}

p {
    font-size: $fontText;
    line-height: 22px;
    color: $black;
}

/*   Decorators    */

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.exrate {
    font-size: 1rem;
    color: $green;
    padding-right: 8px;
}

::selection {
    color: $white;
    background-color: $black;
}